<template>
	<v-app>
		<Header />
		<slot />
		<Footer />
	</v-app>
</template>
<script setup lang="ts">
useHead({
	titleTemplate: (titleChunk) => {
		return titleChunk ? `${titleChunk}` : 'Diskubre Travel';
	},
});
</script>
